export type Features = {
	allowExactOrderDeliveryLocation: boolean
	allowKlarnaIntegration: boolean
	allowUserInvitesToBeSentWithSms: boolean
	consumerSelfRegistrationSettings: ConsumerSelfRegistrationSettings
	coredinationIntegrationMode: CoredinationIntegrationMode
	discountCodeMode: DiscountCodeMode
	notificationsEnabled: boolean
	orderActivityRenderMode: OrderActivityRenderMode
	orderUIProductImagesWithLogos: boolean
	orderUIShowSidebar: boolean
	orderUiAllowAnonymousOrders: boolean
	orderUiAllowLoggingIn: boolean
	orderUiAllowUploadingOrderImages: boolean
	orderUiLoginPlacement: OrderUILoginPlacement
	orderUiOrderReferencesMode: OrderReferenceMode
	orderUiProductSelectionMode: ProductSelectionMode
	orderUiRenderTransportZones: boolean
	orderUiShowOrgNumber: boolean
	pwaDisplay: string
}

export enum CoredinationIntegrationMode {
	Disabled = "Disabled",
	CreateOnAccept = "CreateOnAccept",
	CreateOnCreation = "CreateOnCreation",
}

export enum OrderUILoginPlacement {
	Hide = "Hide",
	HeaderRight = "HeaderRight",
	SideMenuBottom = "SideMenuBottom",
}

export enum OrderActivityRenderMode {
	NoRender = "NoRender",
	WithoutChat = "WithoutChat",
	WithChat = "WithChat",
}

export enum OrderReferenceMode {
	Hide = "Hide",
	ShowExternalReference = "ShowExternalReference",
}

export enum DiscountCodeMode {
	Hide = "Hide",
	AllowEntry = "AllowEntry",
	//AllowCreation = "AllowCreation",
}

export enum ProductSelectionMode {
	Modal = "Modal",
	FullPage = "FullPage",
}

export enum ConsumerSelfRegistrationMode {
	AllowCreateDirect = "AllowCreateDirect",
	AllowCreatePending = "AllowCreatePending",
	Deny = "Deny",
}

export type ConsumerSelfRegistrationSettings = {
	mode: ConsumerSelfRegistrationMode
}
