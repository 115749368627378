import Lottie from "lottie-react"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { AuthNotExpired, GetAuthToken, useAuth } from "../Auth/AuthContext"
import { Loader } from "../Loader/Loader"
import { Thinking } from "../Lottie/AnimationComponents"
import { fixLottieColor, skrappyDarkerGreen } from "../Lottie/Helpers"
import sent from "../Lottie/Sent.json"
import { API } from "../network/API"
import { AccentButton } from "../Orders/Components/Form/Buttons/Buttons"
import { EventQueue } from "../Shared/eventQueue"
import { useThrowAsync } from "../Shared/throwAsync"
import { Footer } from "./Footer/Footer"
import { Header } from "./Header/Header"
import { allSent, sortOrders } from "./Helpers"
import { Order } from "./Order/Order"
import style from "./OrderAcceptance.module.css"
import { OrderCollectionFromAPI } from "./OrderCollection"

export const OrderAcceptance = () => {
	const auth = useAuth()

	const key = new URLSearchParams(useLocation().search).get("key") ?? ""
	/**
	 * Using `useNavigate` instead of `useNavigator` since this component is used outside of the Client-Context.
	 */
	const navigate = useNavigate()

	const throwAsync = useThrowAsync()
	const [collection, setCollection] = useState<OrderCollectionFromAPI | null>(null)
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (collection && allowLoggingIn()) {
			const clientIdentifier = collection.client.identifier
			auth.init(clientIdentifier).then(() => {
				const url = `my-pages/orders?filters={"collectionNumber":${collection.collectionNumber}}`
				if (!!GetAuthToken(clientIdentifier) && AuthNotExpired(clientIdentifier)) {
					navigate(`/${clientIdentifier}/${url}`)
				} else {
					navigate(`/${clientIdentifier}/order`)
					EventQueue.dispatchEvent("openLoginModal", {
						redirectTo: url,
					})
				}
			})
		}
	}, [collection, navigate])

	const getOrders = useCallback(() => {
		setLoading(true)
		API.get<OrderCollectionFromAPI>(`/orders-v1/${key}`)
			.then((coll) => {
				coll.orders = sortOrders(coll.orders)
				setCollection(coll)
				setLoading(false)
			})
			.catch((error) => {
				setCollection(null)
				setLoading(false)
			})
	}, [key])

	useEffect(() => {
		getOrders()
	}, [getOrders])

	const allowLoggingIn = () => {
		return collection?.client.features?.orderUiAllowLoggingIn === true
	}

	function sendResponses() {
		if (!collection) {
			return
		}

		API.post<OrderCollectionFromAPI, void>(`/orders-v1/handle/${key}`)
			.then((coll) => {
				coll.orders = sortOrders(coll.orders)
				setCollection(coll)
			})
			.catch((error) => {
				throwAsync(new Error(`Unable to load order acceptance data for: ${key}`, { cause: error }))
			})
	}

	function loadingContent() {
		return (
			<div style={{ height: "350px", position: "relative" }}>
				<div className={style.lottieHeader}>Laddar in data</div>
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			</div>
		)
	}

	function noCollectionContent() {
		return (
			<div>
				<div className={style.lottieHeader}>Ingen orderinformation kunde hittas</div>
				<div className={style.lottieText}>Verfiera att länken är korrekt, eller testa att ladda om datan?</div>
				<div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
					<AccentButton
						style={{ maxWidth: "250px", marginTop: "20px" }}
						onClick={() => {
							getOrders()
						}}>
						Ladda om datan
					</AccentButton>
				</div>
				<div className={style.lottieWrapper}>
					<Thinking color={skrappyDarkerGreen} className={style.lottieAnimation} autoPlay loop />
				</div>
			</div>
		)
	}

	function content() {
		if (loading) {
			return loadingContent()
		}

		if (!collection) {
			return noCollectionContent()
		}

		if (collection && allowLoggingIn()) {
			// this means redirect will be triggered, so we show loading until we're transferred away
			return loadingContent()
		}

		return (
			<>
				{allSent(collection.orders) && (
					<>
						<div className={style.lottieHeader}>Ert svar har skickats till kunden</div>
						<div className={style.lottieText}>Separata mail per order har skickats till er kund</div>
						<div className={style.lottieWrapper}>
							<Lottie animationData={fixLottieColor(sent)} className={style.lottieAnimation} autoPlay loop />
						</div>
					</>
				)}
				<div className={style.orderHeading}>Beställningsid: {collection.collectionNumber}</div>
				{collection.orders.map((order) => (
					<Order key={order.id} order={order} />
				))}
				{allSent(collection.orders) ? null : (
					<div>
						<button className={style.sendButton} onClick={sendResponses}>
							Skicka svar
						</button>
						<div className={style.footerText}>Säkerställ att ovan ordrar är korrekta.</div>
						<div className={style.footerText}>
							Vid tryck på "Skicka svar" så skickas ett bekräftelsemail till kund för de ordrar som är
							hanterade.
						</div>
					</div>
				)}
			</>
		)
	}

	return (
		<div>
			<Header />
			<div className={style.wrapper}>{content()}</div>
			<Footer />
		</div>
	)
}
