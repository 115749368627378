import { faEye, faEyeSlash, faLock, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { zodResolver } from "@hookform/resolvers/zod/dist/zod"
import { isEmpty } from "lodash"
import { FC, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { AbsolutCentered } from "../../AbsolutCentered/AbsolutCentered"
import { useClient } from "../../Client/ClientAndUserProvider"
import { CoredinationIntegrationMode } from "../../Client/FeatureTypes"
import { PenIcon } from "../../Icons/Icon"
import { Loader } from "../../Loader/Loader"
import { API } from "../../network/API"
import { FinalizeButton } from "../../Orders/Components/Form/Buttons/Buttons"
import { ModulePopup } from "../../Orders/Components/ModulePopup/ModulePopup"
import orderConfirmStyle from "../../Orders/Components/OrderConfirmCustomerInformation/OrderConfirmCustomerInformation.module.css"
import { SbtH4 } from "../../Orders/Components/Text/SbtH4/SbtH4"
import { SbtRHFError } from "../../Orders/Components/Text/SbtInvalid/SbtRHFError"
import { PermissionAreaLocation, usePermissions } from "../../PermissionContext"
import consumerInfoStyle from "../CustomerPortalConsumerInformation/CustomerPortalConsumerInformation.module.css"
import { onWheelRef } from "../CustomerPortalCustomerDetails/CustomerPortalCustomerDetails"
import myAccountStyle from "../CustomerPortalMyAccount/CustomerPortalMyAccount.module.css"
import style from "./CoredinationSettings.module.css"
import {
	GetCoredinationSettings,
	GetCoredinationSettingsResponse,
	SetCoredinationSettingsRequest,
	SetCoredinationSettingsResponse,
} from "./CoredinationSettingsModel"

type EditCoredinationSettingsProps = {
	settings: GetCoredinationSettings | null
	onClose: () => void
	onDone: (settings: GetCoredinationSettings) => void
}

const EditCoredinationSettingsFormSchema = z.object({
	apiKey: z.string().min(1, "Du måste ange en API-nyckel"),
	newSecret: z.string().min(1, "Du måste ange en API secret").nullable(),
	editSecret: z.string().nullable(),
})

type EditCoredinationSettingsFormSchemaType = z.input<typeof EditCoredinationSettingsFormSchema>

const EditCoredinationSettings: FC<EditCoredinationSettingsProps> = ({ settings, onClose, onDone }) => {
	const client = useClient()
	const permissions = usePermissions()

	const [showSecret, setShowSecret] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const isNew = settings === null

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty, isValid },
	} = useForm<EditCoredinationSettingsFormSchemaType>({
		resolver: async (data, context, options) => {
			return zodResolver(EditCoredinationSettingsFormSchema)(data, context, options)
		},
		defaultValues: {
			apiKey: settings?.apiKey,
			newSecret: isNew ? "" : null,
			editSecret: isNew ? null : "",
		},
	})

	async function onSubmit(data: EditCoredinationSettingsFormSchemaType) {
		if (!permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Update)) {
			return
		}

		setSubmitting(true)
		let secret = data.newSecret
		if (secret === null) {
			if (isEmpty(data.editSecret)) {
				secret = null
			} else {
				secret = data.editSecret
			}
		}
		const reqObj: SetCoredinationSettingsRequest = {
			coredinationSettings: {
				apiKey: data.apiKey,
				secret: secret,
			},
		}

		API.postWithRetries<SetCoredinationSettingsResponse>(
			`/customer-portal/coredination-settings-v1/${client.identifier}`,
			reqObj,
		)
			.then((res) => {
				onDone(res.coredinationSettings)
			})
			.catch(() => {})
	}

	if (!permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Update)) {
		return null
	}

	return (
		<ModulePopup className={style.modal} onClose={onClose} disableCloseWithEsc={submitting}>
			<div className={style.wrapper}>
				<div
					className={consumerInfoStyle.headerText}
					style={{ marginTop: "-15px", marginBottom: "35px", marginRight: "30px" }}>
					Redigera coredinationinställningar
				</div>
				<form className={style.form} onSubmit={handleSubmit((data) => onSubmit(data))} autoComplete="off">
					<div className={orderConfirmStyle.fields}>
						<label>
							<SbtH4>API-nyckel*</SbtH4>
							<input
								className={orderConfirmStyle.input}
								disabled={submitting}
								{...register("apiKey")}
								placeholder="3b7c88f7-16d0-4620-8927-9c286ef85c18"
							/>
							<SbtRHFError error={errors.apiKey} />
						</label>
						<label>
							<SbtH4>Secret{isNew ? "*" : null}</SbtH4>
							<div className={style.inputWrapper}>
								<div>
									<FontAwesomeIcon icon={faLock} />
								</div>
								<input
									className={orderConfirmStyle.input}
									disabled={submitting}
									type={showSecret ? "text" : "password"}
									{...register(isNew ? "newSecret" : "editSecret")}
									autoComplete="off"
									placeholder="API-secret"
								/>
								<FontAwesomeIcon
									style={{ marginLeft: "-33px", cursor: "pointer" }}
									icon={showSecret ? faEyeSlash : faEye}
									onClick={() => {
										setShowSecret(!showSecret)
									}}
								/>
							</div>
							<SbtRHFError error={isNew ? errors.newSecret : errors.editSecret} />
						</label>
					</div>
					<FinalizeButton className={style.finalize} disabled={submitting || !isDirty || !isValid} type="submit">
						Spara inställningar{" "}
						{submitting ? <FontAwesomeIcon style={{ color: "gray" }} spin={true} icon={faSpinner} /> : null}
					</FinalizeButton>
				</form>
			</div>
		</ModulePopup>
	)
}

export const CoredinationSettings: FC = () => {
	const client = useClient()
	const permissions = usePermissions()

	const [coredinationSettings, setCoredinationSettings] = useState<GetCoredinationSettings | "None" | null | "Loading">(
		"Loading",
	)
	const [edit, setEdit] = useState<boolean>(false)

	function getSettings(identifier: string) {
		if (!permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Read)) {
			return
		}

		API.getWithRetries<GetCoredinationSettingsResponse>(`/customer-portal/coredination-settings-v1/${identifier}`, true)
			.then((response) => {
				setCoredinationSettings(response?.coredinationSettings ?? "None")
			})
			.catch(() => {
				setCoredinationSettings(null)
			})
	}

	useEffect(() => {
		if (
			client.features.coredinationIntegrationMode === CoredinationIntegrationMode.Disabled ||
			!permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Read)
		) {
			return
		}
		getSettings(client.identifier)
	}, [client.features.coredinationIntegrationMode, client.identifier, permissions])

	if (
		client.features.coredinationIntegrationMode === CoredinationIntegrationMode.Disabled ||
		!permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Read)
	) {
		return null
	}

	if (coredinationSettings === null) {
		return null
	}

	if (coredinationSettings === "Loading") {
		return (
			<div className={consumerInfoStyle.wrapper} style={{ minHeight: "250px" }}>
				<AbsolutCentered>
					<Loader />
				</AbsolutCentered>
			</div>
		)
	}

	return (
		<>
			{permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Update) && edit ? (
				<EditCoredinationSettings
					settings={coredinationSettings !== "None" ? coredinationSettings : null}
					onDone={() => {
						getSettings(client.identifier)
						setEdit(false)
					}}
					onClose={() => {
						setEdit(false)
					}}
				/>
			) : null}
			<div className={consumerInfoStyle.wrapper}>
				<div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
					<div className={consumerInfoStyle.headerText}>Coredinationinställningar</div>
					{permissions.isAllowed(PermissionAreaLocation.Client_Third_Party_Integrations_Update) ? (
						<div>
							<PenIcon
								size={22}
								iconClassName={myAccountStyle.editIcon}
								onClick={() => {
									setEdit(true)
								}}
							/>
						</div>
					) : null}
				</div>
				<div style={{ marginTop: "35px" }}>
					<div className={consumerInfoStyle.textInputWithLabel}>
						<label>API-nyckel</label>
						<span
							ref={onWheelRef}
							className={consumerInfoStyle.textAsInput}
							title={coredinationSettings !== "None" ? coredinationSettings.apiKey : undefined}>
							{coredinationSettings !== "None" ? coredinationSettings.apiKey : null}
						</span>
					</div>
				</div>
			</div>
		</>
	)
}
