import { exhaustive } from "exhaustive"
import { isPlainObject, isString } from "lodash"
import { FC, useState } from "react"
import { useSearchParams } from "react-router-dom"
import { z } from "zod"
import { LoginResponse } from "../../Auth/Auth.types"
import { useAuth } from "../../Auth/AuthContext"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ConsumerSelfRegistrationMode } from "../../Client/FeatureTypes"
import { HandPointingIcon } from "../../Icons/Icon"
import { HappyCustomer, Sent, WeFix } from "../../Lottie/AnimationComponents"
import { useNavigator } from "../../Navigator/useNavigator"
import { API, ServerError } from "../../network/API"
import { AccentButton } from "../../Orders/Components/Form/Buttons/Buttons"
import {
	formatPhoneNumberForSweden,
	invalidOrganizationNumberMessage,
	invalidPersonNumberMessage,
	invalidPhoneNumberMessage,
	organizationNumberRegex,
	personNumberRegex,
	validatePhoneNumberForSweden,
} from "../../Orders/Components/Form/constants"
import { cls } from "../../Shared/cls"
import { EventQueue } from "../../Shared/eventQueue"
import {
	SteppedForm,
	SteppedFormBox,
	SteppedFormBoxInputType,
	SteppedFormBoxNextResult,
	SteppedFormBoxState,
	SteppedFormCompletedStepProps,
	SteppedFormConfirmStepProps,
} from "../../Shared/SteppedForm/SteppedForm"
import style from "./NewCustomerPage.module.css"

enum ConsumerTypes {
	PrivatePerson = "PrivatePerson",
	Company = "Company",
}

const BaseNewConsumerSchema = z.object({
	name: z.string().min(1, "Ett namn krävs").max(64, "För långt namn"),
})

const NewCompanyConsumerSchema = z
	.object({
		type: z.literal(ConsumerTypes.Company),
		orgNumber: z
			.string()
			.min(1, "Ett organisationsnummer krävs")
			.regex(organizationNumberRegex, invalidOrganizationNumberMessage),
	})
	.merge(BaseNewConsumerSchema)

const NewPrivatePersonConsumerSchema = z
	.object({
		type: z.literal(ConsumerTypes.PrivatePerson),
		personNumber: z.string().min(1, "Ett personnummer krävs").regex(personNumberRegex, invalidPersonNumberMessage),
	})
	.merge(BaseNewConsumerSchema)

const NewConsumerSchema = NewCompanyConsumerSchema.or(NewPrivatePersonConsumerSchema)

type NewConsumerSchemaType = z.input<typeof NewConsumerSchema>

const BaseUserSchema = z.object({
	email: z.string().email("Felaktigt format på e-post"),
	phoneNumber: z
		.string()
		.min(3, invalidPhoneNumberMessage)
		.refine(validatePhoneNumberForSweden, { message: invalidPhoneNumberMessage }),
	firstName: z.string().min(1).max(64, "För långt förnamn"),
	lastName: z.string().min(1).max(64, "För långt efternamn"),
})

const NewDirectUserSchema = z
	.object({
		type: z.literal("NewUser"),
		rawPassword: z.string().min(8, "Lösenordet måste vara minst 8 tecken långt").max(64, "För långt lösenord"),
		rawPasswordAgain: z.string().min(8, "Lösenordet måste vara minst 8 tecken långt").max(64, "För långt lösenord"),
	})
	.merge(BaseUserSchema)
	.superRefine((val, ctx) => {
		if (val.rawPassword && val.rawPasswordAgain && val.rawPassword !== val.rawPasswordAgain) {
			ctx.addIssue({
				path: ["rawPasswordAgain"],
				code: z.ZodIssueCode.custom,
				message: "Lösenordet måste matcha i båda rutorna",
			})
		}
	})

const PendingUserSchema = z
	.object({
		type: z.literal("PendingUser"),
	})
	.merge(BaseUserSchema)

const NewUserSchema = NewDirectUserSchema.or(PendingUserSchema)
type NewUserSchemaType = z.input<typeof NewUserSchema>

type NewCustomerPageProps = {}

const NegativeResponses = {
	ClientNotFound: "ClientNotFound",
	ConsumerExists: "ConsumerExists",
	EmailInUse: "EmailInUse",
	UsernameInUse: "UsernameInUse",
	InvalidPhoneNumber: "InvalidPhoneNumber",
	PasswordsMismatch: "PasswordsMismatch",
	PhoneNumberInUse: "PhoneNumberInUse",
	ConsumerSelfRegistrationNotAllowed: "ConsumerSelfRegistrationNotAllowed",
	PendingConsumerCreated: "PendingConsumerCreated",
} as const

type NegativeResponsesType = (typeof NegativeResponses)[keyof typeof NegativeResponses]

const SubmitError = {
	Unknown: "Unknown",
	InvalidData: "InvalidData",
} as const

const SubmitStates = { ...NegativeResponses, ...SubmitError }

type SubmitStatesType = (typeof SubmitStates)[keyof typeof SubmitStates]

type NewConsumerBase = {
	name: string
}

type NewCompanyConsumer = NewConsumerBase & {
	type: "Company"
	orgNumber: string
}

type NewPrivatePersonConsumer = NewConsumerBase & {
	type: "PrivatePerson"
	personNumber: string
}

type NewConsumer = NewCompanyConsumer | NewPrivatePersonConsumer

type NewUser = {
	email: string
	phoneNumber: string
	firstName: string
	lastName: string
	rawPassword: string
	rawPasswordAgain: string
}

type PendingUser = {
	email: string
	phoneNumber: string
	firstName: string
	lastName: string
}

type CreateNewCustomerAndUser = {
	consumer: NewConsumer
	user: NewUser
}

type CreatePendingCustomerAndUser = {
	consumer: NewConsumer
	user: PendingUser
}

enum FormStepId {
	ConsumerInfo = "ConsumerInfo",
	UserInfo = "UserInfo",
}

type FormStepState = {
	id: FormStepId
	state: SteppedFormBoxState
	closedHeader?: string
	closedSubHeader?: string
	data: object
}

export const NewCustomerPage: FC<NewCustomerPageProps> = () => {
	const auth = useAuth()
	const navigator = useNavigator()
	const client = useClient()

	const [queryParams] = useSearchParams()

	const [selectedConsumerType, setSelectedConsumerType] = useState<ConsumerTypes | null>(ConsumerTypes.Company)

	const [submitting, setSubmitting] = useState(false)

	const [formStepStates, setFormStepStates] = useState<Record<FormStepId, FormStepState>>({
		[FormStepId.ConsumerInfo]: {
			id: FormStepId.ConsumerInfo,
			state: SteppedFormBoxState.Open,
			data: {},
		},
		[FormStepId.UserInfo]: {
			id: FormStepId.UserInfo,
			state: SteppedFormBoxState.ClosedNotEditable,
			data: {},
		},
	})

	function onSubmit(consumer: NewConsumerSchemaType, user: NewUserSchemaType): Promise<LoginResponse | SubmitStatesType> {
		let promise: Promise<LoginResponse>

		if (client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.AllowCreatePending) {
			if (user.type !== "PendingUser") {
				return Promise.resolve(SubmitStates.InvalidData)
			}

			const data: CreatePendingCustomerAndUser = {
				consumer: consumer,
				user: user,
			}

			promise = API.post<LoginResponse, CreatePendingCustomerAndUser>(
				`/customer-portal/new-customer-v1/${client.identifier}/create-pending`,
				data,
			)
		} else {
			if (user.type !== "NewUser") {
				return Promise.resolve(SubmitStates.InvalidData)
			}

			const data: CreateNewCustomerAndUser = {
				consumer: consumer,
				user: user,
			}

			promise = API.post<LoginResponse, CreateNewCustomerAndUser>(
				`/customer-portal/new-customer-v1/${client.identifier}/create`,
				data,
			)
		}

		setSubmitting(true)
		return promise.then(
			(res) => {
				return res
			},
			(err) => {
				setSubmitting(false)
				if (err instanceof ServerError) {
					if (err?.data && isPlainObject(err?.data) && "message" in err?.data) {
						const message = err.data.message as NegativeResponsesType
						return exhaustive(message, {
							ClientNotFound: () => SubmitStates.ClientNotFound,
							ConsumerExists: () => SubmitStates.ConsumerExists,
							EmailInUse: () => SubmitStates.EmailInUse,
							UsernameInUse: () => SubmitStates.UsernameInUse,
							InvalidPhoneNumber: () => SubmitStates.InvalidPhoneNumber,
							PasswordsMismatch: () => SubmitStates.PasswordsMismatch,
							PhoneNumberInUse: () => SubmitStates.PhoneNumberInUse,
							ConsumerSelfRegistrationNotAllowed: () => SubmitStates.ConsumerSelfRegistrationNotAllowed,
							PendingConsumerCreated: () => SubmitStates.PendingConsumerCreated,
							_: () => SubmitStates.Unknown,
						})
					}
				}

				return SubmitStates.Unknown
			},
		)
	}

	function submitErrorToHumanText(error: SubmitStatesType): string {
		if (!selectedConsumerType) {
			return "Något gick fel, vänligen försök igen."
		}

		return exhaustive(error, {
			ClientNotFound: () => "Något gick fel, vänligen försök igen.",
			ConsumerExists: () =>
				selectedConsumerType === ConsumerTypes.Company
					? `Kunden finns redan registrerad hos oss. Verifiera att detaljerna är korrekta. Om du är ägare eller anställd på företaget kan du kontakta ${client.clientInfo.clientName}, eller dina kollegor, för mer hjälp.`
					: `Kunden finns redan registrerad hos oss. Verifiera att detaljerna är korrekta. Om du är ägare kan du kontakta ${client.clientInfo.clientName} för mer hjälp.`,
			EmailInUse: () => "E-posten används redan av ett existerande konto.",
			UsernameInUse: () => "Något gick fel, vänligen försök igen.",
			InvalidPhoneNumber: () => "Felaktigt format på telefonnummret, vänligen verfiera formatet.",
			PasswordsMismatch: () => "Lösenorden matchar inte, vänligen verifiera dem.",
			PhoneNumberInUse: () => "Telefonnummret används redan av ett existerande konto.",
			ConsumerSelfRegistrationNotAllowed: () => "", // separate screen for this state
			PendingConsumerCreated: () => "", // separate screen for this state
			InvalidData: () => "Något gick fel, vänligen försök igen.",
			Unknown: () => "Något gick fel, vänligen försök igen.",
		})
	}

	function steppedForm() {
		if (!selectedConsumerType) {
			return null
		}

		const box1: SteppedFormBox<NewConsumerSchemaType, FormStepId.ConsumerInfo> = {
			id: FormStepId.ConsumerInfo,
			header: "Kunduppgifter",
			subHeader: `För att lägga en bokning ber vi dig vänligen att fylla i dina uppgifter för att skapa ett kundkonto hos oss.`,
			closedHeader: formStepStates.ConsumerInfo.closedHeader || "",
			closedSubHeader: formStepStates.ConsumerInfo.closedSubHeader || "",
			zodSchema: NewConsumerSchema,
			state: formStepStates.ConsumerInfo.state,
			onNextClick: async (stepId, data) => {
				let submitData: NewConsumer = {
					...data,
				}

				return API.post<void, NewConsumer>(
					`/customer-portal/new-customer-v1/${client.identifier}/check-consumer-exists`,
					submitData,
				)
					.then(() => {
						const resp: SteppedFormBoxNextResult = { type: "success" }
						return resp
					})
					.catch(() => {
						const resp: SteppedFormBoxNextResult = {
							type: "failure",
							message: submitErrorToHumanText(SubmitStates.ConsumerExists),
						}
						return resp
					})
			},
			onSuccess: (stepId, data) => {
				setFormStepStates((x) => {
					exhaustive(data, "type", {
						Company: (it) => {
							x[stepId] = {
								id: stepId,
								state: SteppedFormBoxState.ClosedEditable,
								closedHeader: it.name,
								closedSubHeader: it.orgNumber,
								data: data,
							}
						},
						PrivatePerson: (it) => {
							x[stepId] = {
								id: stepId,
								state: SteppedFormBoxState.ClosedEditable,
								closedHeader: it.name,
								closedSubHeader: it.personNumber,
								data: data,
							}
						},
					})

					x[FormStepId.UserInfo].state = SteppedFormBoxState.Open

					return Object.assign({}, x)
				})
			},
			onEditClick: (stepId) => {
				setFormStepStates((x) => {
					x[stepId].state = SteppedFormBoxState.Open
					x[FormStepId.UserInfo].state = SteppedFormBoxState.ClosedNotEditable

					return Object.assign({}, x)
				})
			},
			inputs: [
				selectedConsumerType === ConsumerTypes.Company
					? {
							title: "Företagsnamn",
							inputPath: "name",
							placeHolder: "Företagsnamn",
					  }
					: {
							title: "Fullständigt namn",
							inputPath: "name",
							placeHolder: "Ditt namn",
					  },
				selectedConsumerType === ConsumerTypes.Company
					? {
							title: "Organisationsnummer",
							inputPath: "orgNumber",
							placeHolder: "541122-8080",
					  }
					: {
							title: "Personnummer",
							inputPath: "personNumber",
							placeHolder: "ÅÅÅÅMMDD-XXXX",
					  },
			],
			defaultValues: {
				type: selectedConsumerType,
			},
			disabled: submitting,
		}

		const box2: SteppedFormBox<NewUserSchemaType, FormStepId.UserInfo> = {
			id: FormStepId.UserInfo,
			header: "Användaruppgifter",
			subHeader: `För att kunna logga in på ditt kundkonto behöver du fylla i detaljerna nedan`,
			closedHeader: formStepStates.UserInfo.closedHeader || "Användaruppgifter",
			closedSubHeader: formStepStates.UserInfo.closedSubHeader || "",
			zodSchema: NewUserSchema,
			state: formStepStates.UserInfo.state,
			onNextClick: async (stepId, data) => {
				let submitData: PendingUser = {
					...data,
				}

				return API.post<void, PendingUser>(
					`/customer-portal/new-customer-v1/${client.identifier}/check-user-exists`,
					submitData,
				)
					.then(() => {
						const resp: SteppedFormBoxNextResult = { type: "success" }
						return resp
					})
					.catch((err) => {
						if (err instanceof ServerError) {
							if (err?.data && isPlainObject(err?.data) && "message" in err?.data) {
								const message = err.data.message as NegativeResponsesType
								const resp: SteppedFormBoxNextResult = {
									type: "failure",
									message: submitErrorToHumanText(message),
								}
								return resp
							}
						}

						const resp: SteppedFormBoxNextResult = {
							type: "failure",
							message: submitErrorToHumanText(SubmitStates.Unknown),
						}
						return resp
					})
			},
			onSuccess: (stepId, data) => {
				setFormStepStates((x) => {
					x[stepId] = {
						id: stepId,
						state: SteppedFormBoxState.ClosedEditable,
						closedHeader: `${data.firstName} ${data.lastName}`,
						closedSubHeader: `${data.email}, ${formatPhoneNumberForSweden(data.phoneNumber)}`,
						data: data,
					}

					return Object.assign({}, x)
				})
			},
			onEditClick: (stepId) => {
				setFormStepStates((x) => {
					x[stepId].state = SteppedFormBoxState.Open

					return Object.assign({}, x)
				})
			},
			inputs: [
				{
					title: "Förnamn",
					inputPath: "firstName",
					placeHolder: "Förnamn",
				},
				{
					title: "Efternamn",
					inputPath: "lastName",
					placeHolder: "Efternamn",
				},
				{
					title: "E-post",
					inputPath: "email",
					placeHolder: "Fyll i e-postadress",
				},
				{
					title: "Telefonnummer",
					inputPath: "phoneNumber",
					placeHolder: "070 XXX XX XX",
				},
				...(client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.AllowCreateDirect
					? [
							{
								title: "Lösenord",
								inputPath: "rawPassword",
								type: SteppedFormBoxInputType.Password,
							},
							{
								title: "Upprepa lösenord",
								inputPath: "rawPasswordAgain",
								type: SteppedFormBoxInputType.Password,
							},
					  ]
					: []),
			],
			defaultValues: {
				type:
					client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.AllowCreateDirect
						? "NewUser"
						: "PendingUser",
			},
			disabled: submitting,
		}

		const steps: SteppedFormBox[] = [box1, box2]

		let completedStep: SteppedFormCompletedStepProps

		if (client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.AllowCreatePending) {
			completedStep = {
				lottieComponent: (
					<Sent
						style={{ maxWidth: "400px", margin: "0 auto", marginBottom: "50px" }}
						color={client.branding.colors.sectionBackgroundText}
					/>
				),
				header: "Din ansökan har skickats in.",
				subHeader: `När ${client.clientInfo.clientName} kollat på er ansökan kommer ni få ett meddelande via e-post eller sms med mer information.`,
				finalizeButtonText: "Till bokningssidan",
				finalizeButtonRedirectPath: "order",
			}
		} else {
			completedStep = {
				lottieComponent: (
					<HappyCustomer
						style={{ maxWidth: "400px", margin: "0 auto", marginBottom: "50px" }}
						color={client.branding.colors.sectionBackgroundText}
					/>
				),
				header: "Välkommen till vår bokningsapp!",
				subHeader: "",
				description: "Ditt kundkonto är nu skapat och du kan fortsätta med din bokning.",
				finalizeButtonText: queryParams.has("returnToCheckout") ? "Fortsätt med bokningen" : "Till bokningssidan",
				finalizeButtonRedirectPath: "order",
				autoRedirect: {
					path: queryParams.has("returnToCheckout") ? "order/checkout" : "order",
					onRedirect: (data) => {
						if (isPlainObject(data) && "token" in (data as object) && "expirationDate" in (data as object)) {
							auth.setLoggedInFromResponse(data as LoginResponse, client.identifier).then(
								() => {},
								() => {},
							)
						}
					},
					description: "Du skickas automatiskt vidare till bokningssidan om...",
					timeInSeconds: 6,
				},
			}
		}

		const confirmStep: SteppedFormConfirmStepProps = {
			data: Object.values(formStepStates).reduce((acc, curr) => {
				acc[curr.id] = curr.data

				return acc
			}, {} as Record<string, object>),
			header:
				client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.AllowCreatePending
					? "Skicka in ansökan?"
					: "Skapa kund?",
			subHeader: "",
			buttonText:
				client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.AllowCreatePending
					? "Skicka in"
					: "Skapa",
			onClick: async (data) => {
				return onSubmit(
					data[FormStepId.ConsumerInfo] as NewConsumerSchemaType,
					data[FormStepId.UserInfo] as NewUserSchemaType,
				).then((res) => {
					if ((!isString(res) && "token" in res) || res === SubmitStates.PendingConsumerCreated) {
						setSubmitting(true)
						const resp: SteppedFormBoxNextResult = {
							type: "success",
							data: res,
						}

						return resp
					} else {
						if (res === SubmitStates.ConsumerSelfRegistrationNotAllowed) {
							// If registration is not allowed, reload the page to force update feature flags, as well as show the correct error screen
							window.location.reload()
						}

						const resp: SteppedFormBoxNextResult = {
							type: "failure",
							message: submitErrorToHumanText(res),
						}

						return resp
					}
				})
			},
			completedStep: completedStep,
		}

		return <SteppedForm steps={steps} finalStep={confirmStep} />
	}

	function regularContent() {
		return (
			<>
				<div className={style.superHeader}>Välkommen till {client.clientInfo.clientName}</div>
				<div className={style.headerAndSubHeaderWrapper}>
					{client.features.consumerSelfRegistrationSettings.mode ===
					ConsumerSelfRegistrationMode.AllowCreatePending ? (
						<>
							<div className={style.header}>Ansök om att bli kund</div>
							<div className={style.subHeaderText}>
								Vänligen fyll i detaljerna i formuläret nedan för att ansöka om att bli kund. Du kommer att
								få en bekräftelse när vi har gått igenom din ansökan.
							</div>
						</>
					) : null}
				</div>

				<div className={style.loginExistingAccountText}>
					Har du redan ett konto?{" "}
					<span
						onClick={() => {
							EventQueue.dispatchEvent("openLoginModal", { redirectTo: "order" })
						}}>
						<strong>Logga in</strong>
					</span>
				</div>

				<div className={style.consumerTypeSelection}>
					<div
						className={cls(
							style.consumerTypeOption,
							{
								[style.consumerTypeOptionSelected]: selectedConsumerType === ConsumerTypes.Company,
							},
							{
								[style.consumerTypeOptionDisabled]: submitting,
							},
						)}
						onClick={() => {
							if (submitting) {
								return
							}
							setSelectedConsumerType(null)

							setTimeout(() => {
								setSelectedConsumerType(ConsumerTypes.Company)
							}, 40)
						}}>
						Företag
					</div>
					<div
						className={cls(
							style.consumerTypeOption,
							{
								[style.consumerTypeOptionSelected]: selectedConsumerType === ConsumerTypes.PrivatePerson,
							},
							{
								[style.consumerTypeOptionDisabled]: submitting,
							},
						)}
						onClick={() => {
							if (submitting) {
								return
							}
							setSelectedConsumerType(null)

							setTimeout(() => {
								setSelectedConsumerType(ConsumerTypes.PrivatePerson)
							}, 40)
						}}>
						Privat
					</div>
				</div>

				{steppedForm()}
			</>
		)
	}

	function registrationDisallowedContent() {
		return (
			<>
				<div className={style.contentWrapper}>
					<div className={style.header} style={{ color: "var(--invalid-color)", margin: "0 auto" }}>
						Registrering ej möjlig
					</div>
					<div className={style.subHeaderText} style={{ color: "var(--invalid-color)", margin: "0 auto" }}>
						För tillfället är det inte möjligt att registrera sig som kund
					</div>

					<WeFix
						style={{ maxWidth: "500px", margin: "0 auto" }}
						color={client.branding.colors.sectionBackgroundText}
					/>
					<div className={style.boxHeader} style={{ margin: "0 auto" }}>
						Vänligen försök igen vid ett senare tillfälle.
					</div>
					<AccentButton
						className={style.goToBookingButton}
						onClick={() => {
							navigator.open("order")
						}}>
						Boka
						<HandPointingIcon size={22} />
					</AccentButton>
				</div>
			</>
		)
	}

	function content() {
		if (client.features.consumerSelfRegistrationSettings.mode === ConsumerSelfRegistrationMode.Deny) {
			return registrationDisallowedContent()
		}

		return regularContent()
	}

	return <div className={style.wrapper}>{content()}</div>
}
