import React, { useCallback, useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { AbsolutCentered } from "../AbsolutCentered/AbsolutCentered"
import { useAuth } from "../Auth/AuthContext"
import { getReloadCount, incrementReloadCountAndReloadPage, reloadCountLimitReached } from "../ErrorBoundary/ErrorBoundary"
import { Loader } from "../Loader/Loader"
import { getLogger, remoteLogPusher } from "../Logging/getLogger"
import { API, ServerError } from "../network/API"
import { PolygonTransportZone, TransportZoneApiResponse } from "../Orders/ProjectInputModule/ProjectInputModule"
import { PermissionContextProvider } from "../PermissionContext"
import { ServiceWorkerContextProvider } from "../ServiceWorkerContext"
import { anyToLoggableString } from "../Shared/anyToLoggableString"
import { EventQueue } from "../Shared/eventQueue"
import { plausible } from "../Shared/plausible"
import { useThrowAsync } from "../Shared/throwAsync"
import { throwIllegalState } from "../Shared/throwIllegalState"
import { ClientInstance, clientInstanceOf } from "./ClientInstance"
import { ClientNotFound } from "./ClientNotFound/ClientNotFound"
import { ConsumerCatalogContextProvider } from "./ConsumerCatalogContext"
import { ConsumerContextProvider } from "./ConsumerContext"
import { GetClientResponse } from "./GetClientResponse"
import { setBrandingCssVariables } from "./setBrandingCssVariables"

export const ClientContext = React.createContext<ClientInstance | null>(null)

export const useClientRoot = () => useClient().identifier

export const useClientContext = () => useContext(ClientContext)

export const useClient = () => {
	const context = useClientContext()
	if (!context) {
		throw new Error("Client context does not exist")
	}
	return context
}

type Props = {
	element: React.ReactNode
}

const logger = getLogger("ClientAndUserProvider")

export const ClientAndUserProvider = ({ element }: Props) => {
	const auth = useAuth()
	const { clientName } = useParams()

	remoteLogPusher.clientIdentifier = clientName ?? null

	const throwAsync = useThrowAsync()
	const [client, setClient] = useState<ClientInstance | "not-found" | null>(null)

	useEffect(() => {
		const id = EventQueue.addEventListener("reload-client-data", (res) => {
			if (clientName && fetchClient) {
				fetchClient(clientName)
			}
		})

		return () => {
			EventQueue.removeEventListener(id)
		}
	}, [])

	const fetchClient = useCallback(
		async (clientName: string) => {
			let clientInstance: ClientInstance | null = await API.getWithRetries<GetClientResponse>(
				`/order-ui/clients-v1/${clientName}`,
				true,
			)
				.then((clientResp) => {
					return clientInstanceOf(clientResp)
				})
				.catch((error: ServerError<unknown>) => {
					if (error.status === 404) {
						setClient("not-found")
						plausible("client-not-found", { props: { clientName: clientName } })
					} else {
						if (reloadCountLimitReached()) {
							throwIllegalState(
								`Unable to fetch client data for: ${clientName}, reload limit reached | ${anyToLoggableString(
									error,
								)}`,
							)
						} else {
							logger.error(
								`Unable to fetch client data for: ${clientName}, the page will be reloaded. Reload count: ${getReloadCount()} | ${anyToLoggableString(
									error,
								)}`,
							)

							setTimeout(() => {
								incrementReloadCountAndReloadPage()
							}, 500)
						}
					}
					return null
				})

			if (!clientInstance) {
				return
			}

			if (clientInstance.features.orderUiRenderTransportZones) {
				let zones: PolygonTransportZone[] = await API.getWithRetries<TransportZoneApiResponse>(
					`/order-ui/transport-zones-v1/${clientInstance.identifier}`,
					true,
					{},
					10,
				)
					.then((x) => x.zones)
					.catch((err) => {
						logger.error(
							`Unable to fetch transport zones for client: ${clientName}, this is recoverable | ${err}`,
						)
						return []
					})
				clientInstance.setTransportZones(zones)
			}

			await auth.init(clientInstance.identifier).catch((error) => {
				throwAsync(new Error(`Unable to initialize auth for client: ${clientName}`, { cause: error }))
			})

			setClient(clientInstance)
			setBrandingCssVariables(clientInstance.branding)
		},
		[throwAsync],
	)

	useEffect(() => {
		if (clientName && fetchClient) {
			fetchClient(clientName)
		} else {
			setClient("not-found")
		}
	}, [fetchClient, clientName])

	if (!client) {
		return (
			<AbsolutCentered>
				<Loader />
			</AbsolutCentered>
		)
	}

	if (client === "not-found") {
		return <ClientNotFound clientName={clientName} />
	}

	return (
		<ClientContext.Provider value={client}>
			<ConsumerContextProvider>
				<ConsumerCatalogContextProvider>
					<PermissionContextProvider>
						<ServiceWorkerContextProvider>{element}</ServiceWorkerContextProvider>
					</PermissionContextProvider>
				</ConsumerCatalogContextProvider>
			</ConsumerContextProvider>
		</ClientContext.Provider>
	)
}
