import { faAngleDown, faAngleUp, faList, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { exhaustive } from "exhaustive"
import { concat, debounce, every, isArray, isEmpty, isNumber, isString, map } from "lodash"
import { ModulePopup, ModulePopupProps } from "Orders/Components/ModulePopup/ModulePopup"
import { ProductImageSlideShow } from "Orders/ProductInformationAndSelectionModule/ProductImageSlideshow"
import { ChangeEvent, FC, ForwardedRef, RefObject, useCallback, useEffect, useRef, useState } from "react"
import ReactMarkdown from "react-markdown"
import { PluggableList } from "react-markdown/lib/react-markdown"
import "react-tooltip/dist/react-tooltip.css"
import rehypeRaw from "rehype-raw"
import { v4 } from "uuid"
import { useClient } from "../../Client/ClientAndUserProvider"
import { ProductCategoryInstance } from "../../Client/ClientInstance"
import { PreferredVATRenderPolicy } from "../../Client/consumerCatalogConfigurationTypes"
import {
	ConsumerCatalogInstance,
	preferredVATRenderPolicyAsHumanStringShortForm,
	useConsumerCatalog,
} from "../../Client/ConsumerCatalogContext"
import {
	ImageRenderMode,
	PackagingMethod,
	PackagingMethodId,
	ProductService,
	ProductServiceId,
	ProductServiceUnit,
	ProductTransportation,
	TransportationId,
} from "../../Client/ProductDefinitionsByCategories"
import { TransportationType } from "../../Client/TransportationDetails"
import { ProductImageType, WasteId } from "../../ProductDefinitions"
import { cls } from "../../Shared/cls"
import { createCollapsiblesFromHtml } from "../../Shared/Collapsible/Collapsible"
import { notNull } from "../../Shared/notNull"
import { currencyFormatter, numberFormatter } from "../../Shared/numberFormatter"
import { StdTooltip } from "../../Shared/StdTooltip/StdTooltip"
import { throwIllegalState } from "../../Shared/throwIllegalState"
import { when } from "../../Shared/when"
import { ExpandableDropdown, ExpandableDropdownRefProps } from "../Components/ExpandableDropdown/ExpandableDropdown"
import {
	ExpandableIncrementor,
	ExpandableIncrementorRefProps,
} from "../Components/ExpandableIncrementor/ExpandableIncrementor"
import { FinalizeButton } from "../Components/Form/Buttons/Buttons"
import { ExpandableIncrementorPill, ExpandableIncrementorPillRefProps } from "../Components/IncrementorPill/IncrementorPill"
import { ProductImage } from "../Components/ProductImage/ProductImage"
import { getDataForTransportSvg, TransportDescription } from "../Components/TransportDescription/TransportDescription"
import { WasteTypeIcon } from "../Components/WasteTypeIcon/WasteTypeIcon"
import { productServiceUnitToHumanText, productServiceUnitToShortHumanText } from "../OrderContainer/Logic"
import {
	AmountWasteProductDefinitionWithPrice,
	GoodsProductDefinitionWithPrice,
	ProductDefinitionWithPrice,
	ProductPackagingWithMethodWithPriceData,
	ProductPriceData,
	showItemSelector,
	WasteWasteProductDefinitionWithPrice,
} from "../OrderContainer/resolveProductDefinitionsWithPriceData"
import { unitFormatter } from "../unit-formatter"
import style from "./ProductInformationAndSelectionModule.module.css"

export type ProductSelectionReturnValue = {
	productId: string
	name: string
	selectedWasteTypeAmounts?: { [key: WasteId]: number }
	category: string
	serviceId?: string
	amount?: number
	packagingMethods?: { [key: PackagingMethodId]: number }
}

type Props = ModulePopupProps & {
	product: ProductDefinitionWithPrice
	category: ProductCategoryInstance
	service?: ProductService
	possibleServices: { [serviceId: string]: ProductService }
	onProductSelected: (data: ProductSelectionReturnValue) => void
	onSetSelectedServiceId: (selectedCategory: string, serviceId: string | null) => void
	descriptionOpen: boolean
	renderInline?: boolean
	nameAndDimensionsClass?: string
}

export type ProductSelectionElement = {
	element: JSX.Element
	count: number
}

function productPrice(
	priceData: ProductPriceData,
	unit: ProductServiceUnit,
	consumerCatalog: ConsumerCatalogInstance,
	packagingMethod?: PackagingMethod,
): JSX.Element | null {
	if (priceData.article == null) {
		if (priceData.type === "EnabledButMissingPrice") {
			return <div className={style.price}>Ej prissatt</div>
		}
		return null
	}

	const article = priceData.article

	let price: number

	if (consumerCatalog.renderVAT) {
		price = article.resolvedLeaf.price + article.resolvedLeaf.price * article.resolvedLeaf.taxPercentage
	} else {
		price = article.resolvedLeaf.price
	}

	const vatTextElement = when(consumerCatalog.preferredVATRenderPolicy || PreferredVATRenderPolicy.IncludeVAT, {
		[PreferredVATRenderPolicy.ExcludeVAT]: (it) => {
			return (
				<>
					<br />
					<span style={{ fontSize: "14px" }}>
						{preferredVATRenderPolicyAsHumanStringShortForm(PreferredVATRenderPolicy.ExcludeVAT)}
					</span>
				</>
			)
		},
		[PreferredVATRenderPolicy.IncludeVAT]: () => {
			return null
		},
		[PreferredVATRenderPolicy.IncludeVATExplicit]: () => {
			return (
				<>
					<br />
					<span style={{ fontSize: "14px" }}>
						{preferredVATRenderPolicyAsHumanStringShortForm(PreferredVATRenderPolicy.IncludeVATExplicit)}
					</span>
				</>
			)
		},
	})

	if (price <= 0) {
		return (
			<div className={style.price}>
				{currencyFormatter(price)}
				{vatTextElement}
			</div>
		)
	}

	if (packagingMethod == null) {
		return (
			<div className={style.price}>
				{currencyFormatter(price)} / {productServiceUnitToHumanText(unit)}
				{vatTextElement}
			</div>
		)
	} else {
		if (packagingMethod.multiplier === 1) {
			return (
				<div className={style.price}>
					{currencyFormatter(price)} / {productServiceUnitToHumanText(packagingMethod.productUnit)}
					{vatTextElement}
				</div>
			)
		} else {
			if (consumerCatalog.renderVAT) {
				price =
					(article.resolvedLeaf.price + article.resolvedLeaf.price * article.resolvedLeaf.taxPercentage) *
					packagingMethod.multiplier
			} else {
				price = article.resolvedLeaf.price * packagingMethod.multiplier
			}
			return (
				<div className={style.price}>
					{currencyFormatter(price)} / {packagingMethod.multiplier}{" "}
					{productServiceUnitToHumanText(packagingMethod.productUnit)}
					{vatTextElement}
				</div>
			)
		}
	}
}

export function goodsCategoryPackagingSelectionElement(
	goodsProduct: GoodsProductDefinitionWithPrice,
	productSelectionReturnValue: ProductSelectionReturnValue | null,
	setProductSelectionReturnValue: (
		data: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		componentId: string,
		clickedPackagingMethodId: string,
	) => void | null,
	consumerCatalog: ConsumerCatalogInstance,
	ref?: ForwardedRef<ExpandableIncrementorRefProps | ExpandableIncrementorPillRefProps | ExpandableDropdownRefProps>,
	currentValue?: ((productId: string, packagingMethodId: string) => number) | null,
): ProductSelectionElement {
	let packagingElements = goodsProduct.packagings
		.map((packaging: ProductPackagingWithMethodWithPriceData) => {
			const packagingMethod = packaging.packagingMethod

			const showSelector = showItemSelector(packaging.priceData)
			let selectionElement = exhaustive(showSelector, {
				Hide: () => null,
				Show: () => {
					const unit = productServiceUnitToHumanText(packagingMethod.productUnit)
					return packagingMethod.visualization.type === "Numeric"
						? goodsCategoryPackagingNumericInput(
								goodsProduct,
								packagingMethod,
								unit,
								productSelectionReturnValue,
								setProductSelectionReturnValue,
								ref,
								currentValue ? currentValue(goodsProduct.id, packagingMethod.id) : undefined,
						  )
						: packagingMethod.visualization.type === "Dropdown"
						? goodsCategoryPackagingDropdownInput(
								goodsProduct,
								packagingMethod,
								productSelectionReturnValue,
								setProductSelectionReturnValue,
								ref,
								currentValue ? currentValue(goodsProduct.id, packagingMethod.id) : undefined,
						  )
						: goodsCategoryPackagingIncrementorInput(
								goodsProduct,
								packagingMethod,
								productSelectionReturnValue,
								setProductSelectionReturnValue,
								ref,
								currentValue ? currentValue(goodsProduct.id, packagingMethod.id) : undefined,
						  )
				},
				ShowCallUs: () => <div className={style.mbText}>Ring oss</div>,
			})
			if (selectionElement == null) return null

			let priceElement = productPrice(
				packaging.priceData,
				packagingMethod.productUnit,
				consumerCatalog,
				packagingMethod,
			)

			return (
				<div key={packagingMethod.id} className={style.productSelectionRow}>
					<div className={style.productSelectionRowName}>
						<span
							style={{ marginBottom: "0", fontSize: "20px" }}
							className={style.twoLineClamp}
							title={packagingMethod.name}>
							{packagingMethod.name}
						</span>
						<div style={{ fontSize: "14px", fontWeight: 100, marginBottom: "10px", maxWidth: "80%" }}>
							{packagingMethod.description}
						</div>
						{priceElement}
					</div>
					<div
						className={style.productSelectionRowIncrementor}
						style={{ width: packagingMethod.visualization.type === "Dropdown" ? "130px" : undefined }}>
						{selectionElement}
					</div>
				</div>
			)
		})
		.filter(notNull)

	let element = <>{packagingElements}</>
	return { element, count: packagingElements.length }
}

function goodsCategoryPackagingNumericInput(
	product: GoodsProductDefinitionWithPrice,
	packagingMethod: PackagingMethod,
	unit: string,
	productSelectionReturnValue: ProductSelectionReturnValue | null,
	setProductSelectionReturnValue: (
		data: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		componentId: string,
		clickedPackagingMethodId: string,
	) => void | null,
	ref?: ForwardedRef<ExpandableIncrementorPillRefProps>,
	currentValue?: number,
) {
	return (
		<ExpandableIncrementorPill
			ref={ref}
			defaultValue={null}
			max={10000}
			text={unit}
			currentValue={currentValue !== undefined ? { value: currentValue, show: currentValue > 0 } : undefined}
			onChange={(value, oldValue, componentId) => {
				if (!setProductSelectionReturnValue) {
					return
				}

				if (value === null || value <= 0) {
					setProductSelectionReturnValue(null, "removeClick", componentId, packagingMethod.id)
					return
				}
				let packagingMethodMap = productSelectionReturnValue?.packagingMethods || {}
				packagingMethodMap[packagingMethod.id] = value
				setProductSelectionReturnValue(
					{
						productId: product.id,
						name: product.name,
						category: product.category.name,
						amount: undefined,
						packagingMethods: packagingMethodMap,
					},
					oldValue ? (value < oldValue ? "removeClick" : "addClick") : "addClick",
					componentId,
					packagingMethod.id,
				)
			}}
			size={40}
			multiplier={packagingMethod.multiplier}
			onBlur={(value, componentId) => {
				if (!setProductSelectionReturnValue) {
					return
				}
				if (value === null || value <= 0) {
					let packagingMethodMap = productSelectionReturnValue?.packagingMethods || {}
					delete packagingMethodMap[packagingMethod.id]
					if (Object.keys(packagingMethodMap).length === 0) {
						setProductSelectionReturnValue(null, "blur", componentId, packagingMethod.id)
					} else {
						setProductSelectionReturnValue(
							{
								productId: product.id,
								name: product.name,
								category: product.category.name,
								amount: undefined,
								packagingMethods: packagingMethodMap,
							},
							"blur",
							componentId,
							packagingMethod.id,
						)
					}
				}
			}}
		/>
	)
}

function goodsCategoryPackagingIncrementorInput(
	product: GoodsProductDefinitionWithPrice,
	packagingMethod: PackagingMethod,
	productSelectionReturnValue: ProductSelectionReturnValue | null,
	setProductSelectionReturnValue: (
		data: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		componentId: string,
		clickedPackagingMethodId: string,
	) => void | null,
	ref?: ForwardedRef<ExpandableIncrementorRefProps>,
	currentValue?: number,
) {
	return (
		<ExpandableIncrementor
			ref={ref}
			name={packagingMethod.id}
			defaultValue={0}
			max={2000}
			min={0}
			currentValue={currentValue !== undefined ? { value: currentValue, show: currentValue > 0 } : undefined}
			onChange={(name, value, action, currentValue, ref) => {
				if (!setProductSelectionReturnValue) {
					return
				}

				if (value > 0) {
					let packagingMethodMap = productSelectionReturnValue?.packagingMethods || {}
					packagingMethodMap[packagingMethod.id] = value
					setProductSelectionReturnValue(
						{
							productId: product.id,
							name: product.name,
							category: product.category.name,
							amount: undefined,
							packagingMethods: packagingMethodMap,
						},
						action,
						ref,
						packagingMethod.id,
					)
				}
			}}
			onBlur={(value, componentId) => {
				if (!setProductSelectionReturnValue) {
					return
				}

				let packagingMethodMap = productSelectionReturnValue?.packagingMethods || {}
				if (value === 0) {
					delete packagingMethodMap[packagingMethod.id]
					if (Object.keys(packagingMethodMap).length === 0) {
						setProductSelectionReturnValue(null, "blur", componentId, packagingMethod.id)
					} else {
						setProductSelectionReturnValue(
							{
								productId: product.id,
								name: product.name,
								category: product.category.name,
								amount: undefined,
								packagingMethods: packagingMethodMap,
							},
							"blur",
							componentId,
							packagingMethod.id,
						)
					}
				}
			}}
		/>
	)
}

function goodsCategoryPackagingDropdownInput(
	product: GoodsProductDefinitionWithPrice,
	packagingMethod: PackagingMethod,
	productSelectionReturnValue: ProductSelectionReturnValue | null,
	setProductSelectionReturnValue: (
		data: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		componentId: string,
		clickedPackagingMethodId: string,
	) => void | null,
	ref?: ForwardedRef<ExpandableDropdownRefProps>,
	currentValue?: number,
) {
	return (
		<ExpandableDropdown
			ref={ref}
			getItems={(indexes) => {
				return indexes.map((idx) => {
					return {
						key: `${(packagingMethod.multiplier * (idx + 1)).toString()} ${productServiceUnitToShortHumanText(
							packagingMethod.productUnit,
						)}`,
						value: idx + 1,
					}
				})
			}}
			currentValue={currentValue !== undefined ? { value: currentValue, show: currentValue > 0 } : undefined}
			unSelectionItemText={`0 ${productServiceUnitToShortHumanText(packagingMethod.productUnit)}`}
			onChange={(item, componentId) => {
				if (!setProductSelectionReturnValue) {
					return
				}

				let packagingMethodMap = productSelectionReturnValue?.packagingMethods || {}
				if (!item || item.value === 0) {
					delete packagingMethodMap[packagingMethod.id]
				} else {
					packagingMethodMap[packagingMethod.id] = isNumber(item.value) ? item.value : parseInt(item.value) || 0
				}

				if (Object.keys(packagingMethodMap).length === 0) {
					setProductSelectionReturnValue(null, "blur", componentId, packagingMethod.id)
				} else {
					setProductSelectionReturnValue(
						{
							productId: product.id,
							name: product.name,
							category: product.category.name,
							amount: undefined,
							packagingMethods: packagingMethodMap,
						},
						"blur",
						componentId,
						packagingMethod.id,
					)
				}
			}}
			defaultValue={null}
		/>
	)
}

export function wasteCategoryAmountSelectionElement(
	product: AmountWasteProductDefinitionWithPrice,
	serviceId: string,
	setProductSelectionReturnValue: (
		data: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		componentId: string,
	) => void | null,
	consumerCatalog: ConsumerCatalogInstance,
	ref?: ForwardedRef<ExpandableIncrementorRefProps>,
	currentValue?: number | null,
): ProductSelectionElement | null {
	const showSelector = showItemSelector(product.priceData)
	let selectionElement = exhaustive(showSelector, {
		Hide: () => null,
		Show: () => (
			<ExpandableIncrementor
				ref={ref}
				name={product.id}
				defaultValue={0}
				min={0}
				max={2000}
				currentValue={
					currentValue !== undefined && currentValue !== null
						? { value: currentValue, show: currentValue > 0 }
						: undefined
				}
				onChange={(name, value, action, currentValue, componentId) => {
					if (!setProductSelectionReturnValue) {
						return
					}

					if (value > 0) {
						setProductSelectionReturnValue(
							{
								productId: product.id,
								name: product.name,
								category: product.category.name,
								serviceId: serviceId,
								amount: value,
							},
							action,
							componentId,
						)
					}
				}}
				onBlur={(value, componentId) => {
					if (!setProductSelectionReturnValue) {
						return
					}

					if (value <= 0) {
						setProductSelectionReturnValue(null, "blur", componentId)
					}
				}}
			/>
		),
		ShowCallUs: () => <div className={style.mbText}>Ring oss</div>,
	})
	if (selectionElement == null) return null

	const priceElement = productPrice(product.priceData, product.service.unit, consumerCatalog)
	let element = (
		<>
			<div className={style.productSelectionRow}>
				<div className={style.productSelectionRowName}>
					<span className={style.twoLineClamp} title={product.name}>
						{product.name}
					</span>

					{priceElement}
				</div>

				<div className={style.productSelectionRowIncrementor}>{selectionElement}</div>
			</div>
		</>
	)
	return { element, count: 1 }
}

export function wasteCategoryWasteSelectionElement(
	product: WasteWasteProductDefinitionWithPrice,
	serviceId: string,
	productSelectionReturnValue: ProductSelectionReturnValue | null,
	setProductSelectionReturnValue:
		| ((
				data: ProductSelectionReturnValue | null,
				action: "text" | "addClick" | "removeClick" | "blur",
				clickedWasteTypeId: string,
				componentId: string,
		  ) => void)
		| null,
	wasteTypeSearchValue: string,
	wasteTypeSearchDebounced: Function | null,
	consumerCatalog: ConsumerCatalogInstance,
	ref?: ForwardedRef<ExpandableIncrementorRefProps>,
	currentValue?: ((productId: string, wasteTypeId: string) => number) | null,
): ProductSelectionElement | null {
	if (isEmpty(product.allowedWaste)) {
		return null
	}

	let possibleWasteTypes = product.pricedWaste.filter(
		(it) => it.wasteType.name.toLocaleLowerCase().indexOf(wasteTypeSearchValue.toLocaleLowerCase()) > -1,
	)

	let wasteTypeElements = possibleWasteTypes
		.map((wastePriceData, wasteTypeIndex) => {
			const wasteType = wastePriceData.wasteType

			const showSelector = showItemSelector(wastePriceData.priceData)
			let selectionElement = exhaustive(showSelector, {
				Hide: () => null,
				Show: () => {
					let value = null
					if (currentValue !== undefined && currentValue !== null) {
						value = currentValue(product.id, wasteType.id)
					}

					return (
						<ExpandableIncrementor
							ref={ref}
							name={wasteType.id}
							defaultValue={productSelectionReturnValue?.selectedWasteTypeAmounts?.[wasteType.id] || 0}
							max={2000}
							min={0}
							currentValue={
								value !== null
									? {
											value: value,
											show: value > 0,
									  }
									: undefined
							}
							onChange={(name, value, action, currentValue, componentId) => {
								if (!setProductSelectionReturnValue) {
									return
								}

								if (value <= 0) {
									return
								}

								let wasteAmountMap = productSelectionReturnValue?.selectedWasteTypeAmounts || {}
								wasteAmountMap[wasteType.id] = value
								setProductSelectionReturnValue(
									{
										productId: product.id,
										name: product.name,
										category: product.category.name,
										serviceId: serviceId,
										amount: undefined,
										selectedWasteTypeAmounts: wasteAmountMap,
									},
									action,
									wasteType.id,
									componentId,
								)
							}}
							onBlur={(value, componentId) => {
								if (!setProductSelectionReturnValue) {
									return
								}

								if (value <= 0) {
									let wasteAmountMap = productSelectionReturnValue?.selectedWasteTypeAmounts || {}
									delete wasteAmountMap[wasteType.id]
									if (Object.keys(wasteAmountMap).length === 0) {
										setProductSelectionReturnValue(null, "blur", wasteType.id, componentId)
									} else {
										setProductSelectionReturnValue(
											{
												productId: product.id,
												name: product.name,
												category: product.category.name,
												serviceId: serviceId,
												amount: undefined,
												selectedWasteTypeAmounts: wasteAmountMap,
											},
											"blur",
											wasteType.id,
											componentId,
										)
									}
								}
							}}
						/>
					)
				},
				ShowCallUs: () => <div className={style.mbText}>Ring oss</div>,
			})
			if (selectionElement == null) return null

			const priceElement = productPrice(wastePriceData.priceData, product.service.unit, consumerCatalog)

			return (
				<div key={wasteType.id + "_wrapper"} className={style.productSelectionRow}>
					<div className={style.productSelectionRowName}>
						<span className={style.twoLineClamp} title={wasteType.name}>
							{wasteType.name}
						</span>
						<span style={{ display: "flex", marginBottom: "auto", marginTop: "-5px" }}>
							<StdTooltip id={wasteType.name + wasteTypeIndex} place={"bottom-start"} openOnClick={true}>
								<div>
									<div style={{ fontSize: "16px", fontWeight: 600 }}>{wasteType.name}</div>
									<div style={{ fontSize: "14px" }}>{wasteType.info}</div>
								</div>
							</StdTooltip>
							<div data-tooltip-id={wasteType.name + wasteTypeIndex} style={{ cursor: "pointer" }}>
								<u style={{ fontSize: "14px" }}>Läs mer</u>
							</div>
						</span>

						{priceElement}
					</div>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "10px",
							justifyContent: "space-between",
						}}>
						<WasteTypeIcon className={style.wasteIcon} wasteType={wasteType.typeImage} />

						<div className={style.productSelectionRowIncrementor}>{selectionElement}</div>
					</div>
				</div>
			)
		})
		.filter(notNull)

	let element = (
		<>
			{wasteTypeElements.length > 3 && wasteTypeSearchDebounced ? (
				<div style={{ display: "flex", width: "100%" }}>
					<div className={style.searchBox}>
						{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
						<a className={style.searchBtn} href="#">
							<FontAwesomeIcon icon={faSearch} />
						</a>
						<input
							type={"search"}
							className={style.searchInput}
							placeholder={"Sök"}
							onChange={(x) => wasteTypeSearchDebounced(x)}
						/>
					</div>
				</div>
			) : null}

			{wasteTypeElements}
		</>
	)
	return { element, count: wasteTypeElements.length }
}

export function wasteCategoryNonDiscreteAmountSelectionElement(
	product: AmountWasteProductDefinitionWithPrice,
	serviceId: string,
	setProductSelectionReturnValue: (
		data: ProductSelectionReturnValue | null,
		action: "text" | "addClick" | "removeClick" | "blur",
		componentId: string,
	) => void | null,
	consumerCatalog: ConsumerCatalogInstance,
	ref?: ForwardedRef<ExpandableIncrementorPillRefProps>,
	currentValue?: number | null,
): ProductSelectionElement | null {
	const showSelector = showItemSelector(product.priceData)
	let psUnit = product.service.unit
	const priceElement = productPrice(product.priceData, psUnit, consumerCatalog)

	const unit = productServiceUnitToHumanText(psUnit)

	let selectionElement = exhaustive(showSelector, {
		Hide: () => null,
		Show: () => (
			<ExpandableIncrementorPill
				ref={ref}
				defaultValue={null}
				max={10000}
				text={unit}
				currentValue={
					currentValue !== undefined && currentValue !== null
						? { value: currentValue, show: currentValue > 0 }
						: undefined
				}
				onChange={(value, oldValue, componentId) => {
					if (!setProductSelectionReturnValue) {
						return
					}

					if (value === null || value <= 0) {
						setProductSelectionReturnValue(null, "removeClick", componentId)
						return
					}

					setProductSelectionReturnValue(
						{
							productId: product.id,
							name: product.name,
							category: product.category.name,
							serviceId: serviceId,
							amount: value,
						},
						oldValue ? (value < oldValue ? "removeClick" : "addClick") : "addClick",
						componentId,
					)
				}}
				size={40}
				multiplier={1}
				onBlur={(value, componentId) => {
					if (!setProductSelectionReturnValue) {
						return
					}

					if (value === null || value <= 0) {
						setProductSelectionReturnValue(null, "blur", componentId)
					}
				}}
			/>
		),
		ShowCallUs: () => <div className={style.mbText}>Ring oss</div>,
	})
	if (selectionElement == null) return null

	let element = (
		<div className={style.productSelectionRow}>
			<div className={style.productSelectionRowName}>
				<span className={style.twoLineClamp} title={product.name}>
					{product.name}
				</span>
				{priceElement}
			</div>
			<div className={style.productSelectionRowIncrementor}>{selectionElement}</div>
		</div>
	)
	return { element, count: 1 }
}

export const ProductInformationAndSelectionModule: FC<Props> = ({
	product,
	category,
	service,
	possibleServices,
	onClose,
	onProductSelected,
	onSetSelectedServiceId,
	descriptionOpen,
	renderInline = false,
	nameAndDimensionsClass,
	...props
}) => {
	const client = useClient()
	const consumerCatalog = useConsumerCatalog()

	const [productInfoExpanded, setProductInfoExpanded] = useState<boolean>(descriptionOpen)
	const [showServiceDropdown, setShowServiceDropdown] = useState<boolean>(false)
	const serviceDropdown = useRef<HTMLDivElement | null>(null)

	const [productSelectionReturnValue, setProductSelectionReturnValue] = useState<ProductSelectionReturnValue | null>(null)

	const [wasteTypeSearchValue, setWasteTypeSearchValue] = useState<string>("")

	const transportId = product.type === "WasteProductDefinition" && service ? product.transportation[service.id] : null

	const transport: ProductTransportation | null = transportId ? client.possibleTransportations[transportId] || null : null

	let type: TransportationType | null = transport ? transport.transportationDetails.type : null

	const isUrlAndFillImage: boolean =
		"url" in product.images.main && product.images.main.renderMode === ImageRenderMode.Fill

	const productDescriptionWrapperRef = useRef<HTMLDivElement>(null)
	const productSecondDescriptionWrapperRef = useRef<HTMLDivElement>(null)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const wasteTypeSearchDebounced = useCallback(
		debounce((event: ChangeEvent<HTMLInputElement>) => {
			if (event.target === null) {
				return
			}
			setWasteTypeSearchValue(event.target.value)
		}, 250),
		[],
	)

	useEffect(() => {
		setProductSelectionReturnValue(null)
	}, [service])

	useEffect(() => {
		document.addEventListener("click", handleClickOutsideDropdown, true)
		return () => {
			document.removeEventListener("click", handleClickOutsideDropdown, true)
		}
	}, [])

	useEffect(() => {
		if (productDescriptionWrapperRef.current != null) {
			createCollapsiblesFromHtml(productDescriptionWrapperRef.current)
		}
	}, [productDescriptionWrapperRef])

	useEffect(() => {
		if (productSecondDescriptionWrapperRef.current != null) {
			createCollapsiblesFromHtml(productSecondDescriptionWrapperRef.current)
		}
	}, [productSecondDescriptionWrapperRef])

	function handleClickOutsideDropdown(event: Event) {
		if (serviceDropdown.current && !serviceDropdown.current.contains(event.target as Node)) {
			setShowServiceDropdown(false)
		}
	}

	function productDimensions(): JSX.Element | null {
		if (
			!product.dimensions ||
			Object.keys(product.dimensions).length === 0 ||
			every(Object.values(product.dimensions), (x) => !x)
		) {
			return null
		}

		return (
			<>
				<div style={{ marginTop: "10px" }} className={style.sectionText}>
					{product.dimensions.maxWeight ? (
						<>
							<strong>Maxlast</strong>: {numberFormatter(product.dimensions?.maxWeight?.value || 0)}{" "}
							{product.dimensions?.maxWeight?.unit}
						</>
					) : null}{" "}
					{product.dimensions.volume ? (
						<>
							<strong>Volym</strong>: {product.dimensions?.volume?.value}{" "}
							{unitFormatter(product.dimensions?.volume?.unit!)}
						</>
					) : null}{" "}
					{product.dimensions.weight ? (
						<>
							<strong>Vikt</strong>: {product.dimensions?.weight?.value}{" "}
							{unitFormatter(product.dimensions?.weight?.unit!)}
						</>
					) : null}
				</div>
				<div className={style.sectionText}>
					{product.dimensions.width ? (
						<>
							<strong>Bredd</strong>: {numberFormatter(product.dimensions?.width?.value || 0)}{" "}
							{product.dimensions?.width?.unit}
						</>
					) : null}{" "}
					{product.dimensions.length ? (
						<>
							<strong>Längd</strong>: {product.dimensions?.length?.value}{" "}
							{unitFormatter(product.dimensions?.length?.unit!)}
						</>
					) : null}{" "}
					{product.dimensions.height ? (
						<>
							<strong>Höjd</strong>: {product.dimensions?.height?.value}{" "}
							{unitFormatter(product.dimensions?.height?.unit!)}
						</>
					) : null}
				</div>
			</>
		)
	}

	function productDescription(
		description: string,
		noMarginBottomIfEmpty: boolean,
		ref: RefObject<HTMLDivElement>,
	): JSX.Element {
		return (
			<div
				className={style.productDescription}
				ref={ref}
				style={{ marginBottom: noMarginBottomIfEmpty && !description ? 0 : undefined }}>
				{!isString(description) || description.length === 0 ? null : (
					<ReactMarkdown rehypePlugins={[rehypeRaw] as PluggableList}>{description}</ReactMarkdown>
				)}
			</div>
		)
	}

	function transportationTerms(): JSX.Element | null {
		if (product?.productCustomizations?.hideTransportationTerms) {
			return null
		}

		if (!type || !transport) {
			const transportToNamesMap: { [key: string]: string[] } = {}

			if (product.type === "WasteProductDefinition") {
				Object.entries(product.transportation).forEach(
					([serviceId, transportationId]: [ProductServiceId, TransportationId]) => {
						let possibleService = possibleServices[serviceId]
						let serviceName = possibleService?.name
						if (serviceName) {
							if (isArray(transportToNamesMap[transportationId])) {
								if (!transportToNamesMap[transportationId]?.includes(serviceName)) {
									transportToNamesMap[transportationId]?.push(serviceName)
								}
							} else {
								transportToNamesMap[transportationId] = [serviceName]
							}
						}
					},
				)
			} else if (product.type === "GoodsProductDefinition") {
				product.packagings.forEach((packaging) => {
					const packagingMethod = client.possiblePackagingMethods[packaging.packagingMethodId]
					const transportationId = packagingMethod?.transportationId

					if (transportationId) {
						if (isArray(transportToNamesMap[transportationId])) {
							if (!transportToNamesMap[transportationId]?.includes(packagingMethod.name)) {
								transportToNamesMap[transportationId]?.push(packagingMethod.name)
							}
						} else {
							transportToNamesMap[transportationId] = [packagingMethod.name]
						}
					}
				})
			}

			return (
				<>
					{Object.entries(transportToNamesMap).map((transportToName) => {
						const transportId = transportToName[0]

						if (!transportId) {
							return <span></span>
						}

						const transportation = client.possibleTransportations[transportId]

						if (!transportation) {
							return <span></span>
						}

						return (
							<span key={v4()} className={style.transportName}>
								<strong style={{ display: "block", marginBottom: "10px", marginTop: "5px" }}>
									{transportToName[1].join(" / ")}
								</strong>
								<TransportDescription
									type={transportation.transportationDetails.type}
									data={getDataForTransportSvg(transportation)}
								/>
							</span>
						)
					})}
					{category.type === "WasteCategory" ? (
						<span className={style.infoText}>Specifik leveransinformation visas efter en tjänst är vald</span>
					) : null}
				</>
			)
		}

		return (
			<>
				<span className={style.subHeader}>Leveransvillkor{transport ? ` för ${transport.name}` : null}</span>
				<TransportDescription type={type} data={getDataForTransportSvg(transport)} />
			</>
		)
	}

	function productSelectionElement(): ProductSelectionElement | null {
		return exhaustive.tag(product, "type", {
			WasteProductDefinition: (product) => {
				if (!service) {
					return null
				}

				return exhaustive(product, "quantityType", {
					None: () => {
						return null
					},
					Waste: (product: WasteWasteProductDefinitionWithPrice): ProductSelectionElement | null => {
						return wasteCategoryWasteSelectionElement(
							product,
							service.id,
							productSelectionReturnValue,
							setProductSelectionReturnValue,
							wasteTypeSearchValue,
							wasteTypeSearchDebounced,
							consumerCatalog,
						)
					},
					Amount: (product: AmountWasteProductDefinitionWithPrice): ProductSelectionElement | null => {
						if (product.service.unit === ProductServiceUnit.Piece) {
							return wasteCategoryAmountSelectionElement(
								product,
								service.id,
								setProductSelectionReturnValue,
								consumerCatalog,
							)
						} else {
							return wasteCategoryNonDiscreteAmountSelectionElement(
								product,
								service.id,
								setProductSelectionReturnValue,

								consumerCatalog,
							)
						}
					},
				})
			},
			GoodsProductDefinition: (product): ProductSelectionElement => {
				return goodsCategoryPackagingSelectionElement(
					product,
					productSelectionReturnValue,
					setProductSelectionReturnValue,
					consumerCatalog,
				)
			},
			_: () => {
				throwIllegalState("Undefined product type: " + product.type)
			},
		})
	}

	function productDescriptionSection() {
		if (product?.productCustomizations?.hideTransportationTerms && !product.description) {
			return null
		}

		return (
			<div className={style.productDescriptionWrapper}>
				<div
					className={style.productDescriptionExpandHeader}
					onClick={() => setProductInfoExpanded(!productInfoExpanded)}>
					<span style={{ fontWeight: 600, wordBreak: "break-all", fontSize: "16px" }}>Transportvillkor</span>
					<span className={style.productDescriptionToggle}>
						{productInfoExpanded ? (
							<FontAwesomeIcon icon={faAngleUp} />
						) : (
							<FontAwesomeIcon icon={faAngleDown} />
						)}
					</span>
				</div>
				<div
					className={cls(style.productDescriptionExpandWrapper, {
						[style.productDescriptionExpanded]: productInfoExpanded,
					})}>
					{productDescription(product.description || "", true, productDescriptionWrapperRef)}
					{transportationTerms()}
				</div>
			</div>
		)
	}

	let beforeDescriptionSelectors = null
	let afterDescriptionSelectors = null
	const productSelection = productSelectionElement()
	if (productSelection != null) {
		const element = product.productCustomizations?.nonBookableProduct ? null : (
			<div className={style.productSelectionNameAndRowsWrapper}>{productSelection.element}</div>
		)
		let count = productSelection.count
		beforeDescriptionSelectors = count > 0 && count <= 3 ? element : null
		afterDescriptionSelectors = count > 3 ? element : null
	}

	const actualContent = (
		<div className={cls(style.wrapper, isUrlAndFillImage ? style.contentAndImageWrapperImageFill : undefined)}>
			{product.images.details.length > 0 ? (
				<ProductImageSlideShow
					images={concat(product.images.main, product.images.details)}
					categoryName={product.categoryName}
				/>
			) : (
				<>
					{"url" in product.images.main ? (
						<div
							className={
								product.images.main.renderMode === ImageRenderMode.Fill
									? style.productUrlImageWrapperFill
									: style.productUrlImageWrapperFit
							}>
							<img
								className={
									product.images.main.renderMode === ImageRenderMode.Fill
										? style.productUrlImageFill
										: style.productUrlImageFit
								}
								src={product.images.main.url}
								alt="Produktbild"
							/>
						</div>
					) : (
						<ProductImage
							client={client}
							categoryName={product.categoryName}
							image={ProductImageType[product.images.main.typeImage]}
							wrapperClassName={style.productImageWrapper}
						/>
					)}
				</>
			)}
			<span className={cls(style.contentWrapper, { [style.contentWrapperImageFill]: isUrlAndFillImage })}>
				<hr
					className={style.hrSection}
					style={{
						marginTop: isUrlAndFillImage ? 0 : "0.5em",
					}}
				/>
				<div className={style.productDetails}>
					<div className={cls(nameAndDimensionsClass)}>
						<div className={style.productNameWrapper}>
							<h2 className={cls(style.productName, style.twoLineClamp)}>{unitFormatter(product.name)}</h2>
							{!renderInline && Object.keys(possibleServices).length > 1 ? (
								<div
									ref={serviceDropdown}
									className={cls(style.serviceSelector, {
										[style.serviceSelectorOpen]: showServiceDropdown,
									})}
									onClick={() => {
										setShowServiceDropdown(!showServiceDropdown)
									}}>
									<span className={style.serviceSelectorValue}>{service?.name || "Alla tjänster"}</span>
									<span className={style.serviceSelectorIcon}>
										<FontAwesomeIcon icon={faList} />
									</span>
									{showServiceDropdown ? (
										<FontAwesomeIcon icon={faAngleUp} />
									) : (
										<FontAwesomeIcon icon={faAngleDown} />
									)}
									{showServiceDropdown ? (
										<div className={style.serviceSelectorDropdown}>
											<div
												key={v4()}
												className={style.serviceSelectorDropdownItem}
												onClick={() => {
													onSetSelectedServiceId(category.name, null)
													setShowServiceDropdown(false)
													setProductSelectionReturnValue(null)
													setProductInfoExpanded(true)
												}}>
												Alla tjänster
											</div>
											{map(possibleServices, (possibleService) => {
												return (
													<div
														key={v4()}
														className={style.serviceSelectorDropdownItem}
														onClick={() => {
															if (!service || possibleService.id !== service.id) {
																onSetSelectedServiceId(category.name, possibleService.id)
																setProductSelectionReturnValue(null)
															}

															setShowServiceDropdown(false)
															setProductInfoExpanded(false)
														}}>
														{possibleService.name}
													</div>
												)
											})}
										</div>
									) : null}
								</div>
							) : null}
						</div>
						{productDimensions()}
						{productDescription(product.secondDescription || "", false, productSecondDescriptionWrapperRef)}
					</div>
					{renderInline ? null : beforeDescriptionSelectors}
					{productDescriptionSection()}
					{renderInline ? null : afterDescriptionSelectors}
				</div>
				{renderInline || product.productCustomizations?.nonBookableProduct ? null : (
					<FinalizeButton
						disabled={!productSelectionReturnValue}
						className={style.finalize}
						onClick={() => {
							onClose()
							onProductSelected(productSelectionReturnValue!)
						}}>
						Lägg till i varukorgen
					</FinalizeButton>
				)}
			</span>
		</div>
	)

	if (renderInline) {
		return actualContent
	}

	return (
		<ModulePopup
			onClose={onClose}
			{...props}
			className={cls({ [style.urlImageFillModalWrapper]: isUrlAndFillImage }, style.modal)}>
			{actualContent}
		</ModulePopup>
	)
}
